import { breakpoints, debounce } from "./utilities.js";
import "./jquery.bxslider.js";

export default function() {

	$('.m-photo-gallery:not(.js-photo-gallery-done)').each(function() {
		var wrapper = $(this);
		var allow = true;
		var gallery = wrapper.find('.js-gallery');
		var thumbs = wrapper.find('.js-thumbs');
		var description = wrapper.find('.js-gallery-description');

		// remove items if less than 2
		if (wrapper.find('.js-gallery-item').length < 2) {
			wrapper.find('.js-gallery-prev, .js-gallery-next, .js-thumbs-bar').remove();
		}

		// gallery
		var slider = gallery.bxSlider({
			mode: 'fade',
			speed: 500,
			pagerCustom: '.js-thumbs',
			nextText: '<a class="m-photo-gallery__controls__menu__link--next"><i class="fas fa-chevron-right"></i></a>',
			prevText: '<a class="m-photo-gallery__controls__menu__link--prev"><i class="fas fa-chevron-left"></i></a>',
			onSlideBefore: function(e) {
				allow = false;
				// set title
				description.fadeOut(200, function() {
					description.text(e.attr('data-description')).fadeIn(500);
				});

			},
			onSlideAfter: function() {
				allow = true;
			}
		});

		// thumbs
		thumbs.bxSlider({
			pager: false,
			slideWidth: 170,
			minSlides: 2,
			maxSlides: 9,
			slideMargin: 12,
			infiniteLoop: false,
			hideControlOnEnd: true
		});

		// prev
		wrapper.find('.js-gallery-prev').off('click').on('click', function() {
			if (!allow) return false;
			slider.goToPrevSlide();
			return false;
		});

		// next
		wrapper.find('.js-gallery-next').off('click').on('click', function() {
			if (!allow) return false;
			slider.goToNextSlide();
			return false;
		});

		// thumbs toggle
		wrapper.find('.js-thumbs-toggle').off('click').on('click', function() {
			if (!allow) return false;
			wrapper.find('.js-thumbs-bar').toggleClass('active');
			return false;
		});

		// Show thumbs on desktop by default 
		window.site.win.on('resize', debounce(function() {
			if (window.matchMedia('(min-width: ' + breakpoints.desktop + 'px)').matches) {
				wrapper.find('.js-thumbs-bar').addClass('active');
			}
			else {
				wrapper.find('.js-thumbs-bar').removeClass('active');
			}
		}))
		window.site.win.trigger('resize');

		// done
		wrapper.addClass('js-photo-gallery-done');
	});
}
